<template>
  <v-card v-if="assessmentForm">
    <v-card-title>
      <v-row class="pa-3" justify="space-between">
        <h3>
          Quarter {{ assessmentForm.quarter }} |
          {{ ucFirst(assessmentForm.type) }} - {{ assessmentForm.title }}
        </h3>
        <div>
          <p class="caption" v-if="assessmentForm.studentAssessments.length !== 0">
            Date Started : {{ sanitizeDate(assessmentForm.studentAssessments.at(-1).dateStart) }}
          </p>
        </div>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <small>{{
        `GRADE ${subject.gradeLevel} | ${subject.name.toUpperCase()}`
      }}</small>
    </v-card-subtitle>
    <v-card-text>
      <v-row class="px-3 py-1" justify="space-between">
        <h4>
          {{ assessmentForm.lesson }}
        </h4>
        <p>
          Duration :
          {{ assessmentForm.duration }}
          minute{{ assessmentForm.duration > 1 ? "s" : "" }}
        </p>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text v-if="submitLoading">
      <small v-show="uploadCount > 0">
        {{ `Uploading files : ${uploadCount} of ${blobs.length}` }}
      </small>
      <v-progress-linear
        :indeterminate="uploadCount === 0"
        :value="uploadProgress"
        color="blue"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong>{{
            `${
              uploadCount > 0
                ? value + "%"
                : "Please wait while form is being submitted..."
            }`
          }}</strong>
        </template>
      </v-progress-linear>
    </v-card-text>
    <v-form v-else>
      <v-card-text>
        <v-card
          class="mb-3"
          elevation="4"
          v-for="(test, testIndex) in assessmentForm.assessmentTests"
          :key="testIndex"
        >
          <v-card-title>
            <v-row class="pl-3 py-2" align="start" justify="space-between">
              <h5>Test {{ testIndex + 1 }}</h5>
              <v-item-group>
                <v-btn
                  @click="test.expand = !test.expand"
                  text
                  :small="$vuetify.breakpoint.smAndUp ? true : false"
                  :x-small="$vuetify.breakpoint.xsOnly ? true : false"
                  :ripple="false"
                >
                  <v-icon :size="$vuetify.breakpoint.smAndUp ? '25' : '20'">
                    mdi-window-{{ test.expand ? "minimize" : "restore" }}
                  </v-icon>
                </v-btn>
              </v-item-group>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            <small>{{
              test.test ? testType(test.method, test.test) : ""
            }}</small>
            <p>Instruction : {{ test.instruction }}</p>
            <v-chip
              class="mr-2"
              v-show="test.caseSensitivity"
              color="blue accent-4"
              label
              outlined
              small
            >
              Case Sensitive
            </v-chip>
          </v-card-subtitle>
          <v-expand-transition>
            <div v-show="test.expand">
              <v-divider></v-divider>
              <v-card-text>
                <v-row v-show="test.questions.length > 0 && test.test">
                  <v-col
                    cols="12"
                    v-for="(question, questionIndex) in test.questions"
                    :key="questionIndex"
                  >
                    <v-card outlined>
                      <v-card-subtitle>
                        <v-row class="py-2 px-3 mb-n7" justify="space-between">
                          <h4 class="py-2">
                            {{ `Question #${questionIndex + 1}` }}
                          </h4>
                          <sub
                            >{{ question.points }} point{{
                              question.points > 1 ? "s" : ""
                            }}</sub
                          >
                        </v-row>
                      </v-card-subtitle>
                      <v-card-text>
                        <p class="mb-2">
                          {{
                            test.test === "enumeration"
                              ? `Enumerate ${question.enumerateCount} `
                              : ""
                          }}{{ question.question }}
                        </p>
                        <v-card
                          v-show="
                            getBlob(
                              `${testIndex}${questionIndex}`,
                              'preview',
                              question.preview
                            )
                          "
                          class="mb-3"
                          outlined
                        >
                          <v-card-subtitle>
                            <v-img
                              :src="
                                getBlob(
                                  `${testIndex}${questionIndex}`,
                                  'preview',
                                  question.preview
                                )
                              "
                              class="mt-3"
                              max-height="500"
                              contain
                            />
                          </v-card-subtitle>
                        </v-card>
                        <div class="mb-5">
                          <v-card
                            outlined
                            v-if="test.test === 'multiplechoice'"
                          >
                            <v-card-text>
                              <v-row align="center" justify="start">
                                <v-col
                                  cols="12"
                                  sm="4"
                                  v-for="(
                                    choice, choiceIndex
                                  ) in question.choices"
                                  :key="choiceIndex"
                                >
                                  <v-textarea
                                    v-if="!test.imageChoices"
                                    readonly
                                    class="mb-n5 clickable"
                                    :value="choice.choice"
                                    :background-color="
                                      question.answerForm[0].answer ===
                                      choice.choice
                                        ? 'green lighten-4'
                                        : ''
                                    "
                                    dense
                                    outlined
                                    @click="
                                      cacheQuestionsData(
                                        'answer',
                                        testIndex,
                                        questionIndex,
                                        choice.choice,
                                        0
                                      )
                                    "
                                    auto-grow
                                    rows="1"
                                  >
                                    <template slot="prepend-inner">
                                      <v-icon size="25"
                                        >mdi-alpha-{{
                                          intToChar(choiceIndex)
                                        }}</v-icon
                                      >
                                    </template>
                                  </v-textarea>
                                  <v-row v-else align="start">
                                    <v-col cols="12">
                                      <v-card
                                        class="mb-3 clickable remove-toggled-state"
                                        :style="
                                          question.answerForm[0].answer ===
                                          choice.choice
                                            ? 'border: thin solid #1b5e20;'
                                            : ''
                                        "
                                        outlined
                                        @click="
                                          cacheQuestionsData(
                                            'answer',
                                            testIndex,
                                            questionIndex,
                                            choice.choice,
                                            0
                                          )
                                        "
                                      >
                                        <v-card-subtitle>
                                          <v-icon size="25"
                                            >mdi-alpha-{{
                                              intToChar(choiceIndex)
                                            }}</v-icon
                                          >
                                          <v-skeleton-loader
                                            v-if="
                                              !getBlob(
                                                `${testIndex}${questionIndex}${choiceIndex}`,
                                                'preview',
                                                choice.preview
                                              )
                                            "
                                            class="mt-3"
                                            boilerplate
                                            type="image"
                                            max-height="150"
                                            max-width="250"
                                          ></v-skeleton-loader>
                                          <v-img
                                            v-else
                                            :src="
                                              getBlob(
                                                `${testIndex}${questionIndex}${choiceIndex}`,
                                                'preview',
                                                choice.preview
                                              )
                                            "
                                            class="mt-3"
                                            max-height="150"
                                            contain
                                          />
                                        </v-card-subtitle>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          <v-row v-else>
                            <v-col
                              v-for="(
                                answer, answerIndex
                              ) in question.answerForm"
                              :key="answerIndex"
                              cols="12"
                            >
                              <v-card
                                class="mb-3"
                                outlined
                                v-show="test.test === 'answersinabox'"
                              >
                                <v-card-subtitle>
                                  Click an answer in the box
                                </v-card-subtitle>
                                <v-card-text>
                                  <v-row>
                                    <v-card
                                      @click="
                                        cacheQuestionsData(
                                          'answer',
                                          testIndex,
                                          questionIndex,
                                          answerInBox,
                                          answerIndex
                                        )
                                      "
                                      class="ma-2 clickable remove-toggled-state"
                                      :ripple="false"
                                      outlined
                                      v-for="(
                                        answerInBox, answerInBoxIndex
                                      ) in getAllAnswers(test.questions)"
                                      :key="answerInBoxIndex"
                                    >
                                      <v-card-text>
                                        {{ answerInBox }}
                                      </v-card-text>
                                    </v-card>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                              <v-card
                                class="mb-3"
                                outlined
                                v-if="
                                  test.test === 'image' || test.test === 'file'
                                "
                              >
                                <v-card-subtitle v-if="test.test === 'image'">
                                  <v-row class="pa-3" justify="space-between">
                                    <p>Answer :</p>
                                    <v-btn
                                      v-if="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'blob',
                                          null
                                        )
                                      "
                                      @click="
                                        cacheQuestionsData(
                                          'removeFile',
                                          testIndex,
                                          questionIndex,
                                          null,
                                          answerIndex
                                        )
                                      "
                                      color="red accent-4"
                                      x-small
                                      :rounded="
                                        $vuetify.breakpoint.smAndUp
                                          ? true
                                          : false
                                      "
                                      :icon="
                                        $vuetify.breakpoint.xsOnly
                                          ? true
                                          : false
                                      "
                                      dark
                                    >
                                      <v-icon
                                        v-if="$vuetify.breakpoint.xsOnly"
                                        size="25"
                                        >mdi-close-box</v-icon
                                      >
                                      <div v-else>Remove</div>
                                    </v-btn>
                                    <small v-else>
                                      Click
                                      <v-icon size="14">mdi-camera</v-icon>
                                      to upload
                                    </small>
                                  </v-row>
                                  <v-skeleton-loader
                                    v-if="
                                      !getBlob(
                                        `${testIndex}${questionIndex}${answerIndex}`,
                                        'preview',
                                        null
                                      )
                                    "
                                    class="mt-3"
                                    boilerplate
                                    type="image"
                                    max-height="300"
                                  ></v-skeleton-loader>
                                  <v-img
                                    v-else
                                    :src="
                                      getBlob(
                                        `${testIndex}${questionIndex}${answerIndex}`,
                                        'preview',
                                        null
                                      )
                                    "
                                    class="mt-3"
                                    max-height="300"
                                    contain
                                  />
                                </v-card-subtitle>
                                <v-card-subtitle v-else>
                                  <v-row class="pa-3" justify="space-between">
                                    <p>Answer :</p>
                                    <v-btn
                                      v-if="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'blob',
                                          null
                                        )
                                      "
                                      @click="
                                        cacheQuestionsData(
                                          'removeFile',
                                          testIndex,
                                          questionIndex,
                                          null,
                                          answerIndex
                                        )
                                      "
                                      color="red accent-4"
                                      x-small
                                      :rounded="
                                        $vuetify.breakpoint.smAndUp
                                          ? true
                                          : false
                                      "
                                      :icon="
                                        $vuetify.breakpoint.xsOnly
                                          ? true
                                          : false
                                      "
                                      dark
                                    >
                                      <v-icon
                                        v-if="$vuetify.breakpoint.xsOnly"
                                        size="25"
                                        >mdi-close-box</v-icon
                                      >
                                      <div v-else>Remove</div>
                                    </v-btn>
                                    <small v-else>
                                      Click
                                      <v-icon size="14">mdi-file</v-icon>
                                      to upload
                                    </small>
                                  </v-row>
                                  <v-skeleton-loader
                                    v-if="
                                      !getBlob(
                                        `${testIndex}${questionIndex}${answerIndex}`,
                                        'blob',
                                        null
                                      )
                                    "
                                    class="mt-3"
                                    boilerplate
                                    type="image"
                                    height="85"
                                    width="85"
                                  ></v-skeleton-loader>
                                  <div v-else>
                                    <v-img
                                      v-show="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'preview',
                                          null
                                        ) === 'pdf'
                                      "
                                      class="mt-3"
                                      src="@/assets/pdf-icon.png"
                                      width="85"
                                    />
                                    <v-img
                                      v-show="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'preview',
                                          null
                                        ) === 'word'
                                      "
                                      class="mt-3"
                                      src="@/assets/word-icon.png"
                                      width="85"
                                    />
                                    <v-img
                                      v-show="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'preview',
                                          null
                                        ) === 'ppt'
                                      "
                                      class="mt-3"
                                      src="@/assets/ppt-icon.png"
                                      width="85"
                                    />
                                    <v-img
                                      v-show="
                                        getBlob(
                                          `${testIndex}${questionIndex}${answerIndex}`,
                                          'preview',
                                          null
                                        ) === 'excel'
                                      "
                                      class="mt-3"
                                      src="@/assets/excel-icon.png"
                                      width="85"
                                    />
                                  </div>
                                  <p>
                                    {{
                                      getBlob(
                                        `${testIndex}${questionIndex}${answerIndex}`,
                                        "blob",
                                        null
                                      )
                                        ? answer.answer
                                        : (answer.answer = null)
                                    }}
                                  </p>
                                </v-card-subtitle>
                                <v-card-actions>
                                  <v-file-input
                                    @change="
                                      handleFileUploads(
                                        test.test,
                                        testIndex,
                                        questionIndex,
                                        $event,
                                        answerIndex
                                      )
                                    "
                                    dense
                                    hide-details
                                    hide-input
                                    solo
                                    :prepend-icon="
                                      test.test === 'image'
                                        ? 'mdi-camera'
                                        : 'mdi-file'
                                    "
                                  />
                                </v-card-actions>
                              </v-card>
                              <div v-else>
                                <div
                                  v-if="
                                    test.test === 'matchingtype' ||
                                    test.test === 'trueorfalse'
                                  "
                                >
                                  <v-select
                                    v-if="test.test === 'trueorfalse'"
                                    @change="
                                      cacheQuestionsData(
                                        'answer',
                                        testIndex,
                                        questionIndex,
                                        $event,
                                        answerIndex
                                      )
                                    "
                                    :items="[
                                      { text: 'TRUE', value: 'true' },
                                      { text: 'FALSE', value: 'false' },
                                    ]"
                                    dense
                                    label="Answer"
                                    outlined
                                    hide-details
                                  />
                                  <v-select
                                    v-else
                                    @change="
                                      cacheQuestionsData(
                                        'answer',
                                        testIndex,
                                        questionIndex,
                                        $event,
                                        answerIndex
                                      )
                                    "
                                    :items="getAllAnswers(test.questions)"
                                    dense
                                    label="Answer"
                                    outlined
                                    hide-details
                                  />
                                </div>
                                <v-textarea
                                  v-else
                                  class="mb-n3"
                                  :readonly="test.test === 'answersinabox'"
                                  :value="answer.answer"
                                  @change="
                                    cacheQuestionsData(
                                      'answer',
                                      testIndex,
                                      questionIndex,
                                      $event,
                                      answerIndex
                                    )
                                  "
                                  dense
                                  label="Answer : "
                                  outlined
                                  auto-grow
                                  rows="1"
                                  hide-details
                                />
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn
          class="px-4"
          @click="submitForm(assessmentForm)"
          dark
          color="green accent-4"
          depressed
          :ripple="false"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import CONSTANTS from "@/config/constants";
import validators from "@/helpers/validationHelper";
import notify from "@/utilities/notifications";
import mimetypes from "@/lib/utilities/mimetypes";
import imageHelper from "@/helpers/imageHelper";
import localforage from "../plugins/localforage";
import _ from "lodash";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "AssessmentProper",
  data() {
    return {
      unlockAssessmentDialog: false,
      submitLoading: false,
      //helpers
      notify: notify,
      mimetypes: mimetypes,
      validators: validators,
      imageHelper: imageHelper,
      testOptions: CONSTANTS.TEST_TYPES,
      //file related
      uploadCount: 0,
      uploadProgress: 0,
      blobs: [],
      //assessment state
      assessmentForm: null,
    };
  },
  computed: {
    ...mapState({
      subject: (state) => state.subjectsModule.subject,
      quarterOptions: (state) => state.subjectsModule.quarterOptions,
      assessment: (state) => state.assessmentModule.assessment,
    }),
    assessmentId() {
      return this.$route.query.id;
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    if (this.assessmentId) {
      const getAssessment = await this.$store.dispatch(
        "assessmentModule/getAssessment",
        this.assessmentId
      );
      if (getAssessment) {
        try {
          var withAnswerForm = await localforage.getItem(`answerForm-${this.assessmentId}`);
          if (!withAnswerForm) {
            this.assessmentForm = await this.setupAssessmentForm(this.assessment);
          } else {
            this.assessmentForm = withAnswerForm;
          }
        } catch (error) {
          this.assessmentForm = await this.setupAssessmentForm(this.assessment);
        }
      } else {
        notify({
          title: "Info",
          status: "info",
          message: "Failed to fetch assessment data, please try again",
        });
        this.$router.push({
          name: "AssessmentAssigned",
        });
      }
    } else {
      notify({
        title: "Info",
        status: "info",
        message: "Failed to fetch assessment data, please try again",
      });
      this.$router.push({
        name: "AssessmentAssigned",
      });
    }

    await this.mapImages(this.assessmentForm, false);
  },
  methods: {
    moment: moment,
    getAllAnswers(questions) {
      let answers = [];
      questions.map((question) => {
        question.answers.map((answer) => {
          answers.push(answer.answer);
        });
      });

      return answers;
    },
    sanitizeDate(date) {
      let sanitizedDate = moment(date).format("YYYY-MM-DD hh:mm A");

      return sanitizedDate;
    },
    async getFileDataUrl(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async mapImages(assessmentForm, mapChanges) {
      let tests = [...assessmentForm.assessmentTests];
      tests.map((test, testIndex) => {
        let questions = [...test.questions];
        questions.map(async (question, questionIndex) => {
          if (question.image) {
            //check for stored file first
            var questionPreview = await localforage.getItem(`question_${question.id}`);
            if (!questionPreview) {
              questionPreview = await this.$store.dispatch(
                "assessmentModule/getFileUrl",
                question.image
              );
            } else {
              if (questionPreview instanceof Blob) {
                questionPreview = await this.getFileDataUrl(questionPreview);
              }
            }

            this.assessmentForm.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = questionPreview;

          } else {
            this.assessmentForm.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = null;
            this.assessmentForm.assessmentTests[testIndex].questions[
              questionIndex
            ].blob = null;
          }

          let choices = [...question.choices];
          if (test.imageChoices) {
            choices.map(async (choice, choiceIndex) => {
              // check for stored file first
              var choicePreview = await localforage.getItem(`choice_${choice.id}`);
              if (!choicePreview) {
                choicePreview = await this.$store.dispatch(
                  "assessmentModule/getFileUrl",
                  choice.choice
                );
              } else {
                if (choicePreview instanceof Blob) {
                  choicePreview = await this.getFileDataUrl(choicePreview);
                }
              }

              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = choicePreview;
            });
          } else {
            choices.map(async (choice, choiceIndex) => {
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = null;
              this.assessmentForm.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].blob = null;
            });
          }
        });
      });
    },
    async setupAssessmentForm(assessment) {
      let assessmentForm = {
        ...assessment,
      };
      delete assessmentForm.teacher;
      delete assessmentForm.createdAt;

      let tests = assessment.assessmentTests?.map((test) => {
        let method = this.testOptions["AUTOMATIC"].find(
          (type) => type.value == test.test
        )
          ? "AUTOMATIC"
          : "MANUAL";

        let questions = test.random
          ? _.shuffle(test.questions)
          : test.questions;

        questions = questions.map((question) => {
          let blob = null,
            preview = null,
            answers = [{ answer: "" }],
            choices = [
              {
                choice: "",
                correct: true,
                blob: null,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                blob: null,
                preview: null,
              },
              {
                choice: "",
                correct: false,
                blob: null,
                preview: null,
              },
            ],
            answerForm = [];

          if (question.image) {
            blob = { name: question.image };
          }

          if (method === "AUTOMATIC") {
            if (test.test === "multiplechoice") {
              let questionChoices = test.random
                ? _.shuffle(question.choices)
                : question.choices;

              choices = questionChoices.map((choice) => {
                let blob = null,
                  preview = null;

                if (test.imageChoices) {
                  blob = { name: choice.choice };
                }

                choice = { ...choice, blob, preview };
                return choice;
              });
            } else {
              answers = question.answers;
            }
          }

          if (test.test === "enumeration") {
            answerForm = Array(question.enumerateCount).fill({
              questionId: question.id,
              answer: "",
            });
          } else {
            answerForm.push({
              questionId: question.id,
              answer: "",
            });
          }

          question = {
            ...question,
            blob,
            preview,
            answers,
            choices,
            answerForm,
          };

          return question;
        });

        test = {
          ...test,
          totalItems: questions.length,
          questions,
          method,
          expand: true,
        };
        return test;
      });

      return { ...assessmentForm, assessmentTests: tests };
    },
    confirmLeave() {
      return window.confirm();
    },

    confirmStayInDirtyForm() {
      return this.blobs.length > 0 && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    async clearCacheByAssessment(id) {
      await localforage.removeItem(`answerForm-${id}`);
      await localforage.removeItem(`offline_assessment_${id}`);
      await localforage.removeItem(`access_code_${id}`)
      //get question and choice blobs arrays
      var questionBlobs = await localforage.getItem(`question_blobs_${id}`);
      await Promise.all(
        (questionBlobs ?? [])?.map(async (questionId) => {
          await localforage.removeItem(`question_${questionId}`);
        })
      );
      var choiceBlobs = await localforage.getItem(`choice_blobs_${id}`);
      await Promise.all(
        (choiceBlobs ?? [])?.map(async (choiceId) => {
          await localforage.removeItem(`choice_${choiceId}`);
        })
      );

      await localforage.removeItem(`question_blobs_${id}`);
      await localforage.removeItem(`choice_blobs_${id}`);
    },
    async submitForm(assessmentForm) {
      this.assessmentForm["subjectId"] = this.subject.id;
      this.uploadProgress = 0;
      this.uploadCount = 0;
      this.submitLoading = true;
      const sanitizedAnswerForm = this.sanitizeAnswerForm(assessmentForm);

      let submitAssessment = await this.$store.dispatch(
        "assessmentModule/submitAnswerForm",
        sanitizedAnswerForm
      );

      //Only upload files if form submitted successfully
      if (submitAssessment) {
        const assessmentId = assessmentForm.id;
        let blobs = this.blobs;
        const uploadPromises = (blobs ?? [])?.map(async (blob, index) => {
        let totalUploads = blobs.length,
          percentage = 100 / totalUploads;

          await this.$store.dispatch(
            "assessmentModule/uploadFileToFirebase",
            blob.blob
          );
          this.uploadCount = index + 1;
          this.uploadProgress += Math.ceil(percentage);
        });

        await Promise.all(uploadPromises);
        notify({
          title: "Success",
          status: "success",
          message: "Assessment submitted successfully",
        });
        await this.clearCacheByAssessment(assessmentId);

        this.resetFields();

        //remove from offline_assessments_subjectid stored in cache
        var offlineAssessments = await localforage.getItem(`offline_assessments_${this.subject.id}`);
        const newOfflineAssessments = (offlineAssessments  ?? [])?.filter(
          (assessment) => assessment.id !== assessmentId
        );

        if (newOfflineAssessments.length > 0) {
          await localforage.setItem(`offline_assessments_${this.subject.id}`, newOfflineAssessments);
        } else {
          await localforage.removeItem(`offline_assessments_${this.subject.id}`);
        }
        

        await this.$store.dispatch(
          "assessmentModule/getAssessments",
          this.subject.id
        );

        this.$router.push({
          name: "AssessmentAssigned",
        });
      } else {
        notify({
          title: "Info",
          status: "info",
          message: "Failed to submit assessment",
        });

        this.$router.push({
          name: "AssessmentAssigned",
        });
      }

      this.submitLoading = false;
    },
    sanitizeAnswerForm(assessmentForm) {
      const assessmentId = assessmentForm.id;
      let attempts = assessmentForm.attempts;
      let studentAssessmentId = 0;
      let answers = [];
      let totalScore = 0;

      if (assessmentForm.studentAssessments.length > 0) {
        studentAssessmentId = assessmentForm.studentAssessments.at(-1).id;
      }

      assessmentForm.assessmentTests?.map((test) => {
        test.questions.map((question) => {
          if (test.method === "AUTOMATIC") {
            let score = 0;
            switch (test.test) {
              case "enumeration":
                let enumerationAnswers = question.answers.map((answer) => {
                    if (test.caseSensitivity) {
                      return answer.answer;
                    } else {
                      return answer.answer.toLowerCase();
                    }
                  }),
                  scoreEach = question.points / question.enumerateCount;

                question.answerForm.map((answer) => {
                  let studentAnswer = null;
                  if (test.caseSensitivity) {
                    studentAnswer = answer.answer;
                  } else {
                    studentAnswer = answer.answer.toLowerCase();
                  }

                  if (enumerationAnswers.includes(studentAnswer)) {
                    enumerationAnswers.splice(
                      enumerationAnswers.indexOf(studentAnswer),
                      1
                    );
                    score = scoreEach;
                    totalScore += scoreEach;
                  } else {
                    score = 0;
                  }

                  answers.push({ ...answer, score });
                });
                break;
              case "multiplechoice":
                question.choices.map((choice) => {
                  if (choice.correct) {
                    question.answerForm.map((answer) => {
                      if (answer.answer == choice.choice) {
                        score = question.points;
                        totalScore += question.points;
                      } else {
                        score = 0;
                      }

                      answers.push({ ...answer, score });
                    });
                  }
                });
                break;
              default:
                let possibleAnswers = question.answers.map((answer) => {
                  if (test.caseSensitivity) {
                    return answer.answer;
                  } else {
                    return answer.answer.toLowerCase();
                  }
                });

                question.answerForm.map((answer) => {
                  let studentAnswer = null;
                  if (test.caseSensitivity) {
                    studentAnswer = answer.answer;
                  } else {
                    studentAnswer = answer.answer.toLowerCase();
                  }

                  if (possibleAnswers.includes(studentAnswer)) {
                    score = question.points;
                    totalScore += question.points;
                  } else {
                    score = 0;
                  }

                  answers.push({ ...answer, score });
                });
                break;
            }
          } else {
            question.answerForm.map((answer) => {
              answers.push({ ...answer, score: null });
            });
          }
        });
      });

      const sanitizedAnswerForm = { assessmentId, attempts, studentAssessmentId, totalScore, answers };
      return sanitizedAnswerForm;
    },

    async resetFields() {
      let assessmentId = this.assessmentId ? this.assessmentId : "";
      await localforage.removeItem(`answerForm-${assessmentId}`);

      this.assessmentForm = null;
      this.blobs = [];
    },
    intToChar(int) {
      const code = "a".charCodeAt(0);

      return String.fromCharCode(code + int);
    },
    testType(method, value) {
      let type = this.testOptions[method].find((type) => type.value == value);
      return type.text;
    },
    getBlob(key, attr, currentValue) {
      let blobs = [...this.blobs],
        value = currentValue;

      if (blobs.length > 0) {
        blobs.find((blob) => {
          if (blob["ref"] === key) {
            value = blob[attr];
          }
        });
      }

      return value;
    },
    getBlobIndex(key) {
      let blobs = [...this.blobs],
        blobIndex = null;

      blobs.find((blob, index) => {
        if (blob["ref"] === key) {
          blobIndex = index;
        }
      });

      return blobIndex;
    },
    async handleFileUploads(key, testIndex, questionIndex, value, answerIndex) {
      if (value instanceof Blob) {
        let questions = [
            ...this.assessmentForm.assessmentTests[testIndex].questions,
          ],
          blobs = [...this.blobs],
          answerForm = [...questions[questionIndex].answerForm];

        switch (key) {
          case "image":
            if (mimetypes.image.includes(value.type)) {
              let dataUrl = await imageHelper.resizeImage(value);
              if (dataUrl) {
                let blob = await imageHelper.dataURLToBlob(dataUrl),
                  timestamp = Date.now(),
                  fileExtension = value.type.replace("image/", ".");
                if (blob) {
                  blob.name = `${questionIndex}${timestamp.toString()}${fileExtension}`;
                  answerForm[answerIndex] = {
                    ...answerForm[answerIndex],
                    answer: blob.name,
                  };

                  questions[questionIndex] = {
                    ...questions[questionIndex],
                    answerForm,
                  };

                  let answerFormBlobIndex = this.getBlobIndex(
                    `${testIndex}${questionIndex}`
                  );

                  if (answerFormBlobIndex !== null) {
                    blobs[answerFormBlobIndex] = {
                      ...blobs[answerFormBlobIndex],
                      blob,
                      preview: dataUrl,
                    };
                  } else {
                    blobs.push({
                      ref: `${testIndex}${questionIndex}${answerIndex}`,
                      blob,
                      preview: dataUrl,
                    });
                  }
                }

                this.blobs = blobs;
                this.assessmentForm.assessmentTests[testIndex].questions =
                  questions;

                await this.setAssessmentForm();
              }
            } else {
              notify({
                title: "Info",
                status: "info",
                message:
                  "Please upload a valid image, accepted images are [.jpeg, .png, .webp, .bmp]",
              });
            }
            break;
          case "file":
            let preview = null,
              blob = null;
            if (mimetypes.pdf.includes(value.type)) {
              preview = "pdf";
              blob = value;
            } else if (mimetypes.docs.includes(value.type)) {
              preview = "word";
              blob = value;
            } else if (mimetypes.ppt.includes(value.type)) {
              preview = "ppt";
              blob = value;
            } else if (mimetypes.xls.includes(value.type)) {
              preview = "excel";
              blob = value;
            } else {
              blob = null;
              notify({
                title: "Info",
                status: "info",
                message:
                  "Please upload a valid file, accepted files are [.docs, .docx, .ppt, .pptx, .xls, .xlsx, .pdf]",
              });
            }

            if (blob) {
              answerForm[answerIndex] = {
                ...answerForm[answerIndex],
                answer: blob.name,
              };

              questions[questionIndex] = {
                ...questions[questionIndex],
                answerForm,
              };

              let answerFormBlobIndex = this.getBlobIndex(
                `${testIndex}${questionIndex}`
              );

              if (answerFormBlobIndex !== null) {
                blobs[answerFormBlobIndex] = {
                  ...blobs[answerFormBlobIndex],
                  blob,
                  preview,
                };
              } else {
                blobs.push({
                  ref: `${testIndex}${questionIndex}${answerIndex}`,
                  blob,
                  preview,
                });
              }

              this.blobs = blobs;
              this.assessmentForm.assessmentTests[testIndex].questions =
                questions;

              await this.setAssessmentForm();
            }
            break;

          default:
            break;
        }
      }
    },
    async cacheQuestionsData(key, testIndex, questionIndex, value, index = null) {
      let questions = [
          ...this.assessmentForm.assessmentTests[testIndex].questions,
        ],
        answerForm = [...questions[questionIndex].answerForm];

      switch (key) {
        case "removeFile":
          answerForm[index] = {
            ...answerForm[index],
            answer: "",
          };
          questions[questionIndex] = {
            ...questions[questionIndex],
            answerForm,
          };

          let answerBlobIndex = this.getBlobIndex(
            `${testIndex}${questionIndex}${index}`
          );

          if (answerBlobIndex !== null) {
            this.blobs.splice(answerBlobIndex, 1);
          }
          break;
        case "answer":
          answerForm[index] = {
            ...answerForm[index],
            answer: value,
          };
          questions[questionIndex] = {
            ...questions[questionIndex],
            answerForm,
          };
          break;
        default:
          break;
      }

      this.assessmentForm.assessmentTests[testIndex].questions = questions;

      await this.setAssessmentForm();
    },
    async setAssessmentForm() {
      let assessmentId = this.assessmentId;
      const assessmentForm = this.assessmentForm;
      await localforage.setItem(`answerForm-${assessmentId}`, assessmentForm);
    },
    ucFirst(value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>
<style scoped>
p {
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.remove-toggled-state {
  color: white !important;
}
</style>
